import React from 'react'
import { motion } from 'framer-motion'

export const Slide = (props) => (
  <motion.div
    initial={{ y: 40, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ type: 'spring', damping: 30, stiffness: 600, delay: props.delay, }}
  >
    {props.children}
  </motion.div>
)

export const ScaleUp = (props) => (
  <motion.div
    initial={{ y: 100, scale: 0.8, opacity: 0 }}
    animate={{ y: 0, scale: 1, opacity: 1 }}
    transition={{ type: 'spring', bounce:0.4, delay: props.delay, }}
  >
    {props.children}
  </motion.div>
)

export const Fade = (props) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ type: 'spring', damping: 100, stiffness: 300, delay: props.delay, }}
  >
    {props.children}
  </motion.div>
)